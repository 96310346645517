import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import "./styles/globals.css";
import React from "react";

const Navbar = lazy(() => import("./modules/navigation/Navbar"));
const Sidebar = lazy(() => import("./modules/navigation/Sidebar"));
const HomePage = lazy(() => import("./modules/home/HomePage"));
const ExplorePage = lazy(() => import("./modules/explore/ExplorePage"));
const NewsPage = lazy(() => import("./modules/news/NewsPage"));
const AboutPage = lazy(() => import("./modules/info/AboutPage"));
const ContactPage = lazy(() => import("./modules/info/ContactPage"));
const LoginPage = lazy(() => import("./modules/auth/LoginPage"));
const SettingsPage = lazy(() => import("./modules/settings/SettingsPage"));
const NotFoundPage = lazy(() => import("./modules/errors/NotFoundPage"));

const LazyLoad = () => {
  useEffect(() => {
    nprogress.start();

    return () => {
      nprogress.done();
    };
  });

  return null;
};

const App: React.FC = () => (
  <Router>
    <Sidebar />
    <div
      className="fixed left-20 mx-20 h-screen
                 flex flex-col"
    >
      <Navbar />
      <Suspense fallback={<LazyLoad />}>
        <section className="mt-32">
          <Routes>
            <Route path="/home" element={<HomePage />} />
            <Route path="/explore" element={<ExplorePage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </section>
      </Suspense>
    </div>
  </Router>
);

export default App;
