import {
  ApolloClient,
  ApolloProvider,
  gql,
  type NormalizedCacheObject
} from "@apollo/client";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { cache } from "./cache";

/*export const typeDefs = gql`
  extend type Query {}
`;*/

/*const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  uri: "http://gql.fortnite-li.ga/graphql",
  headers: {},
  typeDefs
});*/

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
